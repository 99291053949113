import React, { useEffect } from "react"
import $ from 'jquery'


const SearchForm = (props) => {



    useEffect(() => {
      var getUrlParameter = function getUrlParameter(sParam) {
          var sPageURL = window.location.search.substring(1),
              sURLVariables = sPageURL.split('&'),
              sParameterName,
              i;

          for (i = 0; i < sURLVariables.length; i++) {
              sParameterName = sURLVariables[i].split('=');

              if (sParameterName[0] === sParam) {
                  return typeof sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
              }
          }
          return false;
      };

      if(getUrlParameter('s')) {
        $('.st-default-search-input').val(getUrlParameter('s'))
        // setTimeout(function(){
        //   $('.hero-search-form form .hero-search-button').click()
        // },2000)
      }
    }, [])

  return (
    <div className="hero-search-form">
      <form>
        <label htmlFor="search">Search</label>
        <input aria-label="search" id="search" type="text" name="search" className="st-default-search-input" placeholder={`${props.placeholder ? (props.placeholder) : (null)}`} />
        <label htmlFor="search-btn-hero">Searchbtn</label>
        <input aria-label="search btn hero" type="submit" id="search-btn-hero" name="search-btn-hero" value="Search" className="hero-search-button" />
      </form>
    </div>
  );

}

export default SearchForm