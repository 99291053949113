import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SearchForm from "../components/searchform"
import { Link } from "gatsby"
import hero404Image from "../images/error-404.jpg"

const NotFoundPage = () => (
	<Layout>
		<SEO title="404: Not found"
			metaData={null}
			locationOrigin={null}
			domen={null}
		/>

		<div className="error-404-page">

			<div className="hero-404" style={{backgroundImage: `url(${hero404Image})`}}>
				<div className="container">

					<div className="uk-grid">
						<div className="uk-width-1-2@m">
							<h1>Sorry, that page isn't here</h1>
							<p>You didn’t do anything wrong. We just launched a new website and it may have moved somewhere else. Try searching for it using keywords.</p>
							<SearchForm placeholder="Try Searching..." />
							<h2 className="uppercase">Go to the new <Link to="/">homepage</Link> or <Link to="/website-feedback">contact us</Link> about the problem.</h2>
						</div>
					</div>

				</div>
			</div>

			<div className="container">
				<div className="uk-grid info">
					<div className="uk-width-1-3@m text-center">
						<h3>Did you follow a link from here?</h3>
						<p>If you reached this page from another part of pwcva.gov, <Link to="/website-feedback">please let us know so we can correct our mistake.</Link></p>
					</div>
					<div className="uk-width-1-3@m text-center">
						<h3>Did you follow from another site?</h3>
						<p>Links from other sites can sometimes be outdated or misspelled. <Link to="/website-feedback">Let us know</Link> where you came from and we can try to contact the other site in order to fix the problem.</p>
					</div>
					<div className="uk-width-1-3@m text-center">
						<h3>Did you type the URL?</h3>
						<p>You may have typed the address (URL) incorrectly. Check to make sure you’ve got the exact right spelling, capitalization, etc. You can view our footer below for links to our most popular content.</p>
					</div>
				</div>
			</div>


		</div>
	</Layout>
)

export default NotFoundPage
